import 'flowbite';
import 'flatpickr';
import '../charts/setup';
import '~/index.css';

import * as luxon from 'luxon';
import MiniSearch from 'minisearch';
import _ from 'lodash';

window.luxon = luxon;
window.MiniSearch = MiniSearch;
window._ = _;

// This should be last
import '../alpine-setup';
import '../components/dashboard/main';
