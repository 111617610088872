import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import Dashboard from "./Dashboard";

document.addEventListener('DOMContentLoaded', () => {
  const dashboardRoot = document.getElementById('dashboard-root');
  if (! dashboardRoot) {
    return;
  }

  createRoot(dashboardRoot).render(
    <StrictMode>
      <Dashboard />
    </StrictMode>,
  );
});
